import "../../styles/pages/Projects/ProjectItem.scss";
import { Link } from "react-router-dom";

function ProjectItem(props) {
  return (
    <div className="project-item">
      <Link to={props.url}>
        <div className="preojct-item-opacity-layer">
          <img
            src={props.image}
            alt={props.title}
            className="project-item-image"
          ></img>

          <div className="project-item-info">
            <div className="project-item-info-container">
              <div className="project-item-info-title">{props.title}</div>
              <div className="project-item-info-description">{props.sub}</div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default ProjectItem;
