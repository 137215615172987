import "../styles/components/Footage.scss";
import { Trans, useTranslation } from "react-i18next";

function Footage() {
  const { t, i18n } = useTranslation();

  return (
    <div className="footage-container">
      {/* <div className="footage-icon-container">
        <a href="www.google.com">
          <img
            src="/assets/band-logo/Email.png"
            alt="email"
            className="footage-icon"
          />
        </a>
        |
        <a href="www.google.com">
          <img
            src="/assets/band-logo/Facebook.png"
            alt="Facebook"
            className="footage-icon"
          />
        </a>
        |
        <a href="www.google.com">
          <img
            src="/assets/band-logo/Instagram.png"
            alt="Instagram"
            className="footage-icon"
          />
        </a>
        |
        <a href="www.google.com">
          <img
            src="/assets/band-logo/Linkedin.png"
            alt="Linkedin"
            className="footage-icon"
          />
        </a>
      </div> */}
      <div className="copyright">
        Copyright © 2023 Code King PaPa Limited. ALL RIGHTS RESERVED.
      </div>
    </div>
  );
}

export default Footage;
