import "../styles/components/Navbar.scss";
import { useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

function Navbar() {
  const { t, i18n } = useTranslation();
  const navBarData = t("navBarItems", {
    returnObjects: true,
    ns: "navBarMenuItems",
  });

  const [isClicked, setIsClicked] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState("");

  const location = useLocation().pathname;

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  });

  useEffect(() => {
    setIsClicked(false);
  }, []);

  function handleClickMenu() {
    return setIsClicked(!isClicked);
  }
  function handleClickLink() {
    return setIsClicked(false);
  }
  function changeLanguage(lng) {
    i18n.changeLanguage(lng);
    setIsClicked(false);
  }
  return (
    <nav className="nav-bar">
      <Link to="/">
        <img
          src="/assets/logo/logo2.png"
          alt="Code King Papa logo"
          className="nav-logo-image"
        />
      </Link>

      <div onClick={handleClickMenu}>
        <img
          src={
            isClicked
              ? "/assets/materials/menu-close.png"
              : "/assets/materials/menu.png"
          }
          alt="menu button"
          className="menu-icon"
        />
      </div>

      <ul className={isClicked ? "nav-menu active" : "nav-menu"}>
        {navBarData.map((item, i) => {
          return (
            <li key={i} className="nav-link-container">
              <Link
                onClick={handleClickLink}
                to={item.url}
                className={
                  location === `${item.url}` ? "nav-link-selected" : "nav-link"
                }
                preventScrollReset={true}
              >
                {item.name}
              </Link>
            </li>
          );
        })}
        {currentLanguage == "en" ? (
          <li className="change-language" onClick={() => changeLanguage("tc")}>
            <Trans i18nKey="navBarMenuItems:changeLanguage" />
          </li>
        ) : (
          <li className="change-language" onClick={() => changeLanguage("en")}>
            <Trans i18nKey="navBarMenuItems:changeLanguage" />
          </li>
        )}
      </ul>
    </nav>
  );
}
export default Navbar;
