import "../../styles/pages/Home/Home.scss";
import WarningTape from "./WarningTape";
import Card from "./Card";
import WhyChooseUs from "./WhyChooseUs";
import Particle from "./Particle";
import { Trans, useTranslation } from "react-i18next";

function Home() {
  const { t, i18n } = useTranslation();
  const servicesData = t("servicesItems", {
    returnObjects: true,
    ns: "ourServices",
  });
  const whyChooseUsData = t("whyChooseItems", {
    returnObjects: true,
    ns: "whyChooseUs",
  });
  document.title = t("home:document_title");
  return (
    <div className="home-container">
      <section className="section-a">
        <Particle />
        <div className="section-a-text">
          <Trans i18nKey="home:text" />
        </div>
        <h1 className="section-a-title">
          <Trans i18nKey="home:title" />
        </h1>
        <div className="section-a-sub-title">
          <Trans i18nKey="home:sub_title" />
        </div>
        {/* <div className="section-a-description">
          <Trans i18nKey="home:description" />
        </div> */}
      </section>
      <section className="section-b">
        <WarningTape text="TECHNOLOGY | CREATIVE" />
      </section>
      <section className="section-c">
        <div className="section-c-title">
          <Trans i18nKey="home:our_services" />
        </div>
        <div className="section-c-grid">
          {servicesData.map((item, i) => {
            return (
              <Card
                key={i}
                image={item.image}
                title={item.title}
                url={item.url}
                button={item.button}
              />
            );
          })}
        </div>
      </section>
      <section className="section-d">
        <div className="section-d-sub-title">
          <Trans i18nKey="home:some_reasons" />
        </div>
        <div className="section-d-title">
          <Trans i18nKey="home:why_choose_us" />
        </div>
        <div className="section-d-category-container">
          {whyChooseUsData.map((item, i) => {
            return (
              <WhyChooseUs
                key={i}
                number={item.number}
                title={item.title}
                description={item.description}
              />
            );
          })}
        </div>
      </section>
    </div>
  );
}

export default Home;
