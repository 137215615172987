import "./styles/App.scss";
import "./styles/Global.scss";
import Navbar from "./components/Navbar";
import Footage from "./components/Footage";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Projects from "./pages/Projects/Projects";
import Contact from "./pages/Contact/Contact";
import ProjectDetails from "./pages/Projects/ProjectDetails";
import { Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

function App() {
  const { t, i18n } = useTranslation();
  const queryParameters = new URLSearchParams(window.location.search);
  const lang = queryParameters.get("lang");
  function changeLanguage(lng) {
    i18n.changeLanguage(lng);
  }
  useEffect(() => {
    changeLanguage(lang);
  }, []);

  return (
    <div className="App">
      <Navbar />
      <div className="app-container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/projects/:slug" element={<ProjectDetails />} />
        </Routes>
      </div>
      <Footage />
    </div>
  );
}

export default App;
