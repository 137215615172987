import "../../styles/pages/Contact/Contact.scss";
import Form from "./Form";
import { Trans, useTranslation } from "react-i18next";

function Contact() {
  const { t, i18n } = useTranslation();
  document.title = t("contact:document_title");

  return (
    <div className="contact-container">
      <div className="contact-detail-container">
        <div className="contact-text">
          <Trans i18nKey="contact:title" />
        </div>
        <div className="contact-sub">
          <Trans i18nKey="contact:sub" />
        </div>
        <div className="contact-phone">
          {/* <Trans i18nKey="contact:phone" /> */}
        </div>
        <div className="contact-email">
          <Trans i18nKey="contact:email" />
        </div>
      </div>
      <div className="contact-form-container">
        <Form />
      </div>
    </div>
  );
}

export default Contact;
