import "../../styles/pages/Home/Card.scss";
import { Link } from "react-router-dom";
function Card(props) {
  return (
    <div className="card">
      <img
        src="/assets/materials/screw.png"
        alt="screw"
        className="card-screw-a"
      />
      <img src={props.image} alt="card icon" className="card-image" />
      <div className="card-title">{props.title}</div>
      <Link to="/projects">
        <div className="card-button">{props.button}</div>
      </Link>
      <img
        src="/assets/materials/screw.png"
        alt="screw"
        className="card-screw-b"
      />
    </div>
  );
}

export default Card;
