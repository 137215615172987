import "../../styles/pages/About/About.scss";
import { Trans, useTranslation } from "react-i18next";

function About() {
  const { t, i18n } = useTranslation();

  document.title = t("about:document_title");
  return (
    <div className="about-container">
      <section className="section-a">
        <div className="section-a-left">
          <Trans i18nKey="about:slogan" />
        </div>
        <div className="section-a-right">
          <div className="section-a-title">
            <Trans i18nKey="about:title" />
            <span className="main-color">
              &nbsp;
              <Trans i18nKey="about:titleB" />
            </span>
          </div>
          <div className="section-a-description">
            <Trans i18nKey="about:description" />
          </div>
        </div>
      </section>
      <section className="section-b">
        <img
          className="section-b-image"
          alt="about us"
          src="./assets/about-us.png"
        ></img>
      </section>
      <section className="section-c">
        <div className="section-c-left">
          <div className="section-c-image-container-l">
            <img
              className="section-c-image"
              alt="face-a"
              src="./assets/about/1.png"
            ></img>
            <img
              className="section-c-image"
              alt="face-b"
              src="./assets/about/2.png"
            ></img>
          </div>
          <div className="section-c-image-container-r">
            <img
              className="section-c-image"
              alt="face-c"
              src="./assets/about/4.png"
            ></img>
            <img
              className="section-c-image"
              alt="face-d"
              src="./assets/about/3.png"
            ></img>
          </div>
        </div>
        <div className="section-c-right">
          <div className="section-c-since-text">
            <Trans i18nKey="about:since" />
          </div>
          <div className="section-c-title">
            <Trans i18nKey="about:we_are_team" />
          </div>
          <div className="section-c-service">
            <Trans i18nKey="about:tag" />
          </div>

          <div className="section-c-description">
            <Trans i18nKey="about:our_team_description" />
          </div>
        </div>
      </section>
    </div>
  );
}

export default About;
