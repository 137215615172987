import "../../styles/pages/Home/WhyChooseUs.scss";

function WhyChooseUs(props) {
  return (
    <>
      <div className="category">
        <div className="category-number">{props.number}</div>
        <div className="category-title">
          {props.title}
          <div className="category-description">{props.description}</div>
        </div>
      </div>
    </>
  );
}

export default WhyChooseUs;
