import "../../styles/pages/Home/WarningTape.scss";

function WarningTape(props) {
  return (
    <div className="warning-tape">
      <div className="warning-tape-text">{props.text}</div>
    </div>
  );
}

export default WarningTape;
