import { useForm } from "react-hook-form";
import "../../styles/pages/Contact/Form.scss";
import emailjs from "@emailjs/browser";
import { Trans, useTranslation } from "react-i18next";

function Form() {
  const { t, i18n } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  function onSubmit(data) {
    //console.log(data);
    emailjs
      .send("service_i1hqntc", "template_xq8wr1c", data, "lmsc4V3NecUG4FdXO")
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
        },
        (err) => {
          console.log("FAILED...", err);
        }
      );
    reset();
    alert("Message is submited");
  }
  return (
    <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
      <div className="form-title">
        <Trans i18nKey="form:title" />
      </div>
      <div className="form-input-container">
        <div className="form-label">
          <Trans i18nKey="form:name" />
        </div>
        <div className="form-input-error-container">
          <input
            className="form-input"
            {...register("name", {
              required: true,
              minLength: 3,
              maxLength: 40,
              pattern: /^[A-Za-z ]+$/i,
            })}
            //aria-invalid={errors.name ? "true" : "false"}
          />
          {errors.name?.type === "required" && (
            <div className="form-error-message">
              <Trans i18nKey="form:name_error_a" />
            </div>
          )}
          {errors.name?.type === "minLength" && (
            <div className="form-error-message">
              <Trans i18nKey="form:name_error_b" />
            </div>
          )}
          {errors.name?.type === "maxLength" && (
            <div className="form-error-message">
              <Trans i18nKey="form:name_error_c" />
            </div>
          )}
          {errors.name?.type === "pattern" && (
            <div className="form-error-message">
              <Trans i18nKey="form:name_error_d" />
            </div>
          )}
        </div>
      </div>
      <br></br>
      <div className="form-input-container">
        <div className="form-label">
          <Trans i18nKey="form:email" />
        </div>
        <div className="form-input-error-container">
          <input
            className="form-input"
            {...register("email", {
              required: true,
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            })}
            // aria-invalid={errors.email ? "true" : "false"}
          />
          {errors.email?.type === "required" && (
            <div className="form-error-message">
              <Trans i18nKey="form:email_error_a" />
            </div>
          )}
          {errors.email?.type === "pattern" && (
            <div className="form-error-message">
              <Trans i18nKey="form:email_error_b" />
            </div>
          )}
        </div>
      </div>
      <br></br>
      <div className="form-input-container">
        <div className="form-label">
          <Trans i18nKey="form:phone" />
        </div>
        <div className="form-input-error-container">
          <input
            className="form-input"
            {...register("phone", {
              minLength: 8,
              maxLength: 8,
              required: true,
              pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
            })}
            // aria-invalid={errors.email ? "true" : "false"}
          />
          {errors.phone?.type === "required" && (
            <div className="form-error-message">
              <Trans i18nKey="form:phone_error_a" />
            </div>
          )}
          {errors.phone?.type === "pattern" && (
            <div className="form-error-message">
              <Trans i18nKey="form:phone_error_b" />
            </div>
          )}
          {(errors.phone?.type === "minLength" ||
            errors.phone?.type === "maxLength") && (
            <div className="form-error-message">
              <Trans i18nKey="form:phone_error_c" />
            </div>
          )}
        </div>
      </div>
      <br></br>
      <div className="form-input-container">
        <div className="form-label">
          <Trans i18nKey="form:message" />
        </div>
        <div className="form-input-error-textarea-container">
          <textarea
            className="form-textarea"
            {...register("message", {
              required: true,
              minLength: 20,
            })}
            // aria-invalid={errors.message ? "true" : "false"}
          />
          {errors.message?.type === "required" && (
            <div className="form-error-message">
              <Trans i18nKey="form:message_error_a" />
            </div>
          )}
          {errors.message?.type === "minLength" && (
            <div className="form-error-message">
              <Trans i18nKey="form:message_error_" />
            </div>
          )}
        </div>
      </div>
      <input className="form-button" type="submit" value={t("form:submit")} />
    </form>
  );
}

export default Form;
