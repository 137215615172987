import "../../styles/pages/Projects/ProjectDetails.scss";
import "../../styles/pages/Projects/ProjectDetails.scss";
import { useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

function ProjectDetails() {
  const { t, i18n } = useTranslation();
  const { slug } = useParams();
  const projectItemsData = t("projectItems", {
    returnObjects: true,
    ns: "portfolio",
  });
  const project = projectItemsData.find((s) => s.slug === slug);
  var categories = [];
  document.title = `${project.title} | Code King PaPa`;
  for (let i = 0; i < project.categories.length; i++) {
    categories.push(project.categories[i]);
  }
  var achievements = [];
  for (let i = 0; i < project.achievements.length; i++) {
    achievements.push(project.achievements[i]);
  }

  return (
    <div className="project-details-container">
      <div className="project-details-text-container">
        <div className="project-details-left-container">
          <div className="project-details-title"> {project.title}</div>
          <div className="project-details-sub">{project.sub}</div>
          <br></br>
          <div className="project-details-year">
            <Trans i18nKey="project:project_periods" />
            {project.year}
          </div>
          <br></br>
          <div className="project-details-categories">
            <Trans i18nKey="project:project_categories" />
            <ul>
              {categories.map((item, i) => {
                return <li key={i}>{item}</li>;
              })}
            </ul>
          </div>
        </div>
        <div className="project-details-line"></div>
        <div className="project-details-right-container">
          <div>
            <Trans i18nKey="project:project_achievements" />
            <div className="project-details-achievements">
              <ul>
                {achievements.map((item, i) => {
                  return <li key={i}>{item}</li>;
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="project-details-image-container">
        <img
          src={"../assets/projects/" + `${project.detail_image[0]}`}
          alt={project.detail_image[0]}
          className="project-details-image"
        ></img>
        <img
          src={"../assets/projects/" + `${project.detail_image[1]}`}
          alt={project.detail_image[1]}
          className="project-details-image"
        ></img>
      </div>
    </div>
  );
}

export default ProjectDetails;
