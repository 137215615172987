import ProjectItem from "./ProjectItem";
import "../../styles/pages/Projects/Projects.scss";
import { Trans, useTranslation } from "react-i18next";

function Projects() {
  const { t, i18n } = useTranslation();
  document.title = t("project:document_title");
  const projectItemsData = t("projectItems", {
    returnObjects: true,
    ns: "portfolio",
  });
  return (
    <div className="projects-container">
      <section className="section-a">
        <div className="projects-title">
          <Trans i18nKey="project:title" />
          <span className="main-color">
            <Trans i18nKey="project:titleB" />
          </span>
        </div>
        <div className="projects-description">
          <Trans i18nKey="project:description" />
        </div>
      </section>
      <div className="projects-items-container">
        {projectItemsData.map((item, i) => {
          return (
            <span key={i}>
              {i % 2 === 1 ? (
                <span className="project-items-margin">
                  <ProjectItem
                    title={item.title}
                    sub={item.sub}
                    image={item.image}
                    url={item.slug}
                  />
                </span>
              ) : (
                <span>
                  <ProjectItem
                    title={item.title}
                    sub={item.sub}
                    image={item.image}
                    url={item.slug}
                  />
                </span>
              )}
            </span>
          );
        })}
      </div>
    </div>
  );
}

export default Projects;
